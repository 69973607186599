import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1071.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">



<path d="M5315 8093 c-456 -30 -879 -224 -1191 -545 -112 -115 -156 -169 -226
-278 -141 -217 -227 -434 -275 -690 -25 -134 -25 -506 0 -635 92 -470 342
-873 709 -1145 113 -84 158 -112 282 -174 492 -250 1090 -254 1601 -12 191 91
338 195 498 354 209 207 359 448 450 727 51 156 56 214 6 70 -23 -66 -46 -122
-50 -125 -4 -3 -11 -18 -14 -33 -4 -16 -14 -31 -22 -34 -8 -3 -12 -12 -9 -20
4 -9 2 -11 -6 -7 -8 5 -9 2 -5 -10 4 -9 -2 -30 -12 -47 -13 -20 -21 -26 -24
-17 -3 7 -6 2 -6 -11 -1 -23 -38 -93 -53 -99 -4 -2 -8 -8 -8 -13 0 -10 -20
-36 -62 -82 -16 -17 -28 -33 -28 -37 0 -4 -34 -41 -76 -84 -78 -79 -172 -157
-259 -216 -27 -18 -52 -36 -55 -39 -10 -11 -133 -73 -204 -103 -236 -99 -430
-134 -686 -125 -320 12 -588 99 -855 280 -111 75 -330 292 -402 398 -127 186
-222 410 -264 624 -20 100 -23 151 -23 305 0 162 3 201 27 310 64 303 200 567
402 779 86 90 243 223 295 251 14 7 27 16 30 20 3 4 59 35 125 68 123 61 330
131 455 152 153 26 555 14 625 -19 11 -5 46 -17 78 -27 31 -9 57 -20 57 -24 0
-4 7 -11 16 -14 8 -3 12 -2 9 4 -10 17 17 10 70 -16 28 -14 40 -23 28 -21 -37
8 -26 -10 14 -24 21 -6 44 -18 51 -26 8 -8 17 -12 20 -9 3 3 -7 15 -22 27 -39
31 -25 34 19 4 21 -14 33 -25 26 -25 -6 0 -11 -4 -11 -10 0 -5 3 -9 8 -8 12 4
95 -56 83 -60 -6 -2 -17 0 -24 6 -28 21 -47 22 -47 1 0 -16 4 -19 20 -14 12 4
20 2 20 -5 0 -7 8 -10 20 -7 11 3 20 1 20 -3 0 -4 17 -18 37 -30 29 -17 34
-23 22 -31 -19 -12 -7 -11 25 1 14 5 26 6 26 2 0 -4 14 -16 31 -26 17 -10 28
-20 25 -23 -3 -3 -22 4 -42 16 -20 12 -39 20 -41 17 -3 -3 10 -14 28 -25 19
-11 39 -23 44 -28 15 -12 99 -67 105 -69 21 -4 40 -18 33 -23 -14 -9 51 -61
65 -52 19 11 6 35 -38 67 -40 29 -55 53 -22 35 32 -17 133 -131 126 -142 -4
-7 -11 -2 -18 12 -12 25 -36 37 -36 19 0 -7 11 -25 24 -41 21 -24 25 -26 31
-11 5 14 12 10 40 -23 19 -22 34 -47 34 -55 -1 -12 -2 -12 -6 -1 -8 21 -63 76
-63 63 0 -5 15 -28 34 -50 26 -32 36 -38 46 -29 10 9 11 7 6 -9 -5 -15 1 -31
19 -52 14 -16 25 -33 25 -36 0 -4 11 -24 25 -44 30 -44 33 -36 5 10 -11 18
-20 38 -20 43 0 12 60 -86 60 -98 0 -5 -4 -5 -10 -2 -5 3 3 -13 18 -36 15 -23
27 -44 28 -48 0 -3 6 -19 13 -36 7 -16 26 -68 42 -115 44 -125 25 -30 -22 113
-149 456 -484 843 -918 1062 -200 100 -423 162 -666 185 -106 10 -153 10 -260
3z m1138 -445 c37 -22 70 -48 59 -48 -6 0 -17 7 -25 15 -8 7 -26 17 -41 21
-14 3 -26 10 -26 15 0 12 6 11 33 -3z m208 -150 c19 -14 45 -36 59 -47 l25
-21 -32 11 c-18 6 -38 9 -45 6 -7 -3 -3 -6 9 -6 12 -1 27 -11 34 -22 12 -23 9
-23 -38 6 -34 21 -42 40 -20 49 8 3 7 8 -6 16 -33 21 -87 72 -87 82 0 6 15 -3
34 -18 18 -16 49 -41 67 -56z m143 -164 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16
0 14 7 11 14 -7z m216 -248 c0 -6 -7 -2 -15 8 -8 11 -15 25 -15 30 0 6 7 2 15
-8 8 -11 15 -25 15 -30z"/>
<path d="M6431 7556 c2 -2 15 -9 29 -15 24 -11 24 -11 6 3 -16 13 -49 24 -35
12z"/>
<path d="M6432 6735 c-57 -18 -106 -57 -137 -109 l-25 -43 -2 -309 c-3 -304
-2 -310 20 -356 33 -66 77 -106 140 -124 80 -23 154 -8 198 43 l22 24 11 -31
10 -31 78 3 78 3 0 245 0 245 -142 3 -143 3 0 -72 c0 -62 2 -71 20 -76 18 -5
20 -14 20 -98 0 -97 -10 -120 -47 -113 -16 3 -19 19 -23 153 -3 83 -3 226 0
320 5 153 7 170 23 173 37 7 47 -16 47 -110 l0 -89 123 3 122 3 -3 77 c-3 97
-23 153 -70 201 -65 66 -214 95 -320 62z"/>
<path d="M4996 6718 c-2 -7 -11 -74 -20 -148 -9 -74 -34 -275 -56 -446 -22
-172 -40 -314 -40 -318 0 -3 56 -6 124 -6 l124 0 7 68 c9 92 9 92 53 92 36 0
40 -3 46 -31 3 -17 6 -53 6 -80 l0 -49 227 0 226 0 -5 273 c-3 149 -2 281 1
291 8 22 32 -119 64 -384 l22 -175 77 -3 76 -3 6 38 c3 21 13 99 21 173 33
286 46 384 50 389 3 2 5 -132 5 -298 l0 -302 107 3 108 3 0 463 1 462 -158 0
c-86 0 -159 -3 -162 -8 -2 -4 -12 -97 -21 -207 -10 -110 -19 -207 -22 -215 -4
-13 -5 -13 -12 0 -7 14 -14 66 -45 330 l-11 95 -157 3 -158 3 -1 -408 -1 -408
-19 110 c-25 143 -90 585 -96 653 l-5 52 -180 0 c-134 0 -180 -3 -182 -12z
m208 -374 c9 -83 16 -165 16 -182 0 -31 -2 -32 -40 -32 -25 0 -40 5 -40 13 -1
20 43 357 46 354 1 -1 10 -70 18 -153z"/>
<path d="M7211 6624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7241 6074 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4870 5691 c-14 -27 -13 -84 2 -99 18 -18 56 -14 68 7 16 31 12 51
-11 51 -16 0 -19 -4 -14 -20 5 -14 2 -20 -9 -20 -12 0 -16 10 -16 36 0 33 2
36 24 30 27 -7 37 0 29 20 -8 21 -60 17 -73 -5z"/>
<path d="M4969 5705 c0 -3 -1 -31 -1 -62 -1 -58 0 -58 31 -61 23 -2 31 1 31
12 0 9 -9 16 -20 16 -17 0 -20 7 -20 50 0 28 -4 50 -10 50 -5 0 -10 -2 -11 -5z"/>
<path d="M5046 5694 c-9 -24 -7 -73 4 -95 12 -21 50 -25 68 -7 13 13 16 75 6
102 -4 10 -18 16 -39 16 -21 0 -35 -6 -39 -16z m54 -49 c0 -24 -5 -35 -15 -35
-10 0 -15 11 -15 35 0 24 5 35 15 35 10 0 15 -11 15 -35z"/>
<path d="M5150 5644 l0 -65 33 3 c32 3 32 3 35 66 l3 62 -36 0 -35 0 0 -66z
m50 26 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
-10z m0 -51 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z"/>
<path d="M5251 5660 c-18 -83 -16 -89 19 -67 17 10 22 9 29 -2 19 -30 28 -1
17 56 -10 47 -16 59 -33 61 -18 3 -23 -4 -32 -48z m39 -11 c0 -11 -4 -18 -10
-14 -5 3 -7 12 -3 20 7 21 13 19 13 -6z"/>
<path d="M5340 5661 c0 -27 3 -56 6 -65 8 -21 64 -22 64 -1 0 8 -9 15 -20 15
-17 0 -20 7 -20 50 0 38 -4 50 -15 50 -11 0 -15 -12 -15 -49z"/>
<path d="M5470 5680 c0 -23 6 -33 25 -42 33 -15 32 -32 -1 -24 -21 6 -25 4
-22 -11 5 -26 54 -30 67 -4 14 26 4 50 -26 62 -28 11 -22 30 7 22 14 -4 20 0
20 11 0 12 -9 16 -35 16 -32 0 -35 -2 -35 -30z"/>
<path d="M5560 5645 l0 -65 35 0 c24 0 35 5 35 15 0 8 -9 15 -20 15 -11 0 -20
7 -20 15 0 8 9 15 20 15 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10
0 6 9 10 20 10 11 0 20 7 20 15 0 10 -11 15 -35 15 l-35 0 0 -65z"/>
<path d="M5654 5697 c-9 -25 0 -110 14 -115 7 -2 12 5 12 17 0 27 16 27 23 1
11 -44 26 -18 24 43 l-2 62 -33 3 c-21 2 -34 -2 -38 -11z m46 -32 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M5744 5693 c3 -10 8 -40 12 -66 5 -41 9 -48 27 -45 16 2 23 15 33 58
17 70 17 70 0 70 -8 0 -17 -17 -21 -42 l-8 -43 -10 43 c-6 25 -16 42 -24 42
-9 0 -12 -7 -9 -17z"/>
<path d="M5850 5645 c0 -37 4 -65 10 -65 6 0 10 28 10 65 0 37 -4 65 -10 65
-6 0 -10 -28 -10 -65z"/>
<path d="M5897 5703 c-9 -8 -9 -86 -1 -107 7 -19 45 -21 62 -4 17 17 15 38 -3
38 -8 0 -15 -4 -15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 16 -10 35 0 34 12 46 26
24 10 -16 26 5 18 24 -6 17 -54 24 -67 10z"/>
<path d="M5990 5644 l0 -65 33 3 c37 3 43 21 9 26 -28 4 -24 26 6 30 27 4 19
22 -9 22 -26 0 -24 18 3 22 38 5 33 28 -6 28 l-36 0 0 -66z"/>
<path d="M6076 5692 c-9 -28 2 -49 30 -56 36 -9 30 -29 -6 -21 -31 7 -36 0
-21 -23 5 -8 21 -12 37 -10 25 2 29 8 32 36 3 27 -1 34 -23 42 -31 12 -25 31
6 23 16 -4 20 -2 17 8 -2 8 -18 15 -35 17 -24 2 -33 -2 -37 -16z"/>
<path d="M6260 5645 l0 -65 35 0 c24 0 35 5 35 15 0 8 -9 15 -20 15 -17 0 -20
7 -20 50 0 38 -4 50 -15 50 -12 0 -15 -14 -15 -65z"/>
<path d="M6344 5696 c-9 -25 1 -109 14 -114 9 -3 12 14 12 62 0 61 -13 87 -26
52z"/>
<path d="M6390 5644 c0 -48 3 -65 13 -61 6 2 12 16 12 30 -1 29 11 24 17 -7 6
-34 26 -28 36 11 l10 38 1 -37 c2 -66 21 -41 21 27 0 58 -2 65 -20 65 -14 0
-20 -7 -20 -22 0 -43 -17 -53 -24 -14 -5 27 -12 36 -26 36 -18 0 -20 -7 -20
-66z"/>
<path d="M6530 5645 c0 -37 4 -65 10 -65 6 0 10 28 10 65 0 37 -4 65 -10 65
-6 0 -10 -28 -10 -65z"/>
<path d="M6570 5701 c0 -6 5 -13 10 -16 6 -3 10 -29 10 -56 0 -64 24 -63 28 2
2 24 7 48 13 54 17 17 9 25 -26 25 -19 0 -35 -4 -35 -9z"/>
<path d="M6659 5706 c-2 -2 -2 -32 -1 -65 l4 -61 34 0 c24 0 34 5 34 15 0 9
-10 15 -26 15 -31 0 -28 23 4 28 30 5 28 22 -3 22 -14 0 -25 5 -25 10 0 6 11
10 25 10 16 0 25 6 25 15 0 10 -10 15 -33 15 -19 0 -36 -2 -38 -4z"/>
<path d="M6750 5646 l0 -66 29 0 c40 0 53 20 49 77 -3 48 -3 48 -40 51 l-38 3
0 -65z m58 2 c-2 -22 -9 -34 -20 -36 -15 -3 -18 4 -18 32 0 30 4 36 21 36 17
0 20 -5 17 -32z"/>
<path d="M1976 4003 c-3 -16 -33 -189 -66 -387 -33 -197 -62 -367 -65 -377 -6
-18 1 -19 133 -19 156 0 152 -2 152 78 0 52 16 72 56 72 41 0 54 -24 54 -101
l0 -49 140 0 140 0 -5 23 c-3 12 -16 83 -30 157 -13 74 -37 203 -53 285 -17
83 -38 194 -48 248 l-18 97 -192 0 -192 0 -6 -27z m239 -419 l7 -74 -41 0
c-40 0 -41 1 -41 33 0 31 23 195 37 267 6 30 9 21 19 -58 6 -52 15 -127 19
-168z"/>
<path d="M2565 3624 l0 -405 255 3 c282 3 308 9 344 68 21 36 31 145 22 224
-9 68 -41 111 -96 127 -44 13 -44 24 -1 34 16 3 39 17 52 31 21 22 24 35 24
102 0 93 -20 139 -74 176 -52 36 -123 46 -341 46 l-185 0 0 -406z m346 245
c11 -22 9 -133 -3 -145 -4 -3 -22 -7 -40 -7 l-33 -2 -3 74 c-2 41 -1 80 2 88
9 22 64 16 77 -8z m-3 -301 c7 -7 12 -44 12 -94 0 -93 -11 -114 -61 -114 l-29
0 0 110 0 110 33 0 c18 0 38 -5 45 -12z"/>
<path d="M3280 3625 l0 -405 230 0 c126 0 251 5 278 11 88 18 122 79 122 214
-1 116 -32 176 -102 197 -21 6 -38 14 -38 18 0 3 16 11 37 16 58 14 78 51 77
138 -2 88 -22 135 -73 170 -52 36 -123 46 -343 46 l-188 0 0 -405z m340 255
c11 -7 15 -28 15 -79 0 -38 -3 -72 -7 -76 -4 -4 -22 -8 -40 -8 l-33 -2 -3 88
-3 87 28 0 c15 0 35 -5 43 -10z m10 -319 c5 -11 10 -50 10 -88 0 -82 -17 -113
-61 -113 l-29 0 0 110 0 110 35 0 c24 0 37 -6 45 -19z"/>
<path d="M4076 4018 c-2 -7 -32 -182 -66 -388 -34 -206 -64 -383 -66 -392 -5
-17 6 -18 134 -18 l140 0 9 73 8 72 39 3 c21 2 43 -1 48 -6 4 -4 11 -38 15
-75 l6 -67 133 0 c125 0 134 1 134 19 0 20 -137 757 -145 779 -3 9 -52 12
-195 12 -144 0 -192 -3 -194 -12z m223 -315 c7 -60 15 -127 18 -150 l6 -43
-41 0 c-22 0 -43 3 -45 8 -6 10 36 292 43 292 4 0 12 -48 19 -107z"/>
<path d="M4890 3625 l0 -406 118 3 117 3 5 245 5 245 45 -248 45 -248 84 3 84
3 40 243 c22 133 42 241 44 238 2 -2 5 -111 6 -242 l2 -239 118 -3 117 -3 0
406 0 405 -177 -2 -178 -3 -3 -25 c-6 -60 -45 -297 -51 -313 -4 -10 -19 51
-35 140 -15 87 -30 168 -33 181 -5 22 -7 22 -179 22 l-174 0 0 -405z"/>
<path d="M5896 4003 c-3 -16 -33 -192 -66 -393 -33 -201 -62 -371 -65 -377 -3
-10 28 -13 134 -13 155 0 151 -2 151 78 0 51 16 72 53 72 43 0 57 -15 57 -64
0 -89 -5 -86 146 -86 74 0 134 3 134 6 0 8 -90 477 -95 494 -2 8 -14 69 -25
135 -11 66 -23 132 -26 148 l-6 27 -193 0 -193 0 -6 -27z m243 -460 c1 -32 0
-33 -39 -33 -38 0 -40 1 -40 31 0 18 10 93 22 168 l22 136 17 -135 c10 -74 18
-150 18 -167z"/>
<path d="M6489 4026 c-2 -2 -3 -185 -1 -405 l2 -401 110 0 110 0 2 177 3 178
65 -175 65 -175 118 -3 117 -3 0 406 0 405 -110 0 -110 0 -2 -177 -3 -178 -22
55 c-11 30 -44 109 -71 175 l-50 120 -109 3 c-60 1 -111 1 -114 -2z"/>
<path d="M7190 3625 l0 -405 110 0 110 0 0 170 c0 94 3 170 8 170 4 0 14 -19
23 -42 9 -24 37 -100 64 -170 l48 -128 118 0 119 0 0 405 0 406 -112 -3 -113
-3 -2 -160 c-1 -88 -2 -167 -2 -175 -1 -13 -74 155 -129 298 l-16 42 -113 0
-113 0 0 -405z"/>
<path d="M7900 3625 l0 -405 130 0 130 0 0 405 0 405 -130 0 -130 0 0 -405z"/>
<path d="M8270 3626 l0 -406 135 0 135 0 0 180 0 180 30 0 c19 0 33 -7 40 -19
6 -11 10 -89 10 -180 l0 -161 125 0 125 0 0 138 c0 75 -3 154 -6 174 -8 44
-46 87 -86 95 -36 7 -36 19 0 26 43 9 72 38 82 82 30 129 -14 240 -110 275
-23 9 -109 15 -257 18 l-223 4 0 -406z m334 248 c11 -10 16 -34 16 -74 0 -49
-4 -61 -22 -74 -12 -9 -30 -16 -40 -16 -16 0 -18 10 -18 90 0 89 0 90 24 90
14 0 32 -7 40 -16z"/>
<path d="M2654 3080 c-82 -33 -118 -198 -64 -288 37 -60 70 -72 206 -72 l122
0 16 40 c10 25 16 70 16 115 l0 75 -115 0 -115 0 0 -25 c0 -35 40 -72 86 -80
67 -11 62 -13 -33 -14 -87 -1 -92 0 -112 25 -26 34 -27 64 -1 97 18 23 27 25
116 29 86 3 98 5 115 27 10 13 19 36 19 52 l0 29 -117 -1 c-65 0 -128 -4 -139
-9z"/>
<path d="M3021 3076 c-42 -23 -50 -53 -51 -174 0 -117 12 -163 45 -176 9 -3
80 -6 157 -6 l140 0 -7 28 c-3 15 -17 38 -31 52 -22 23 -34 25 -120 28 -90 4
-95 5 -93 25 4 59 -2 237 -8 237 -5 -1 -19 -7 -32 -14z"/>
<path d="M3441 3076 c-80 -27 -121 -85 -121 -173 1 -66 29 -125 76 -153 29
-18 56 -25 118 -28 71 -3 87 -1 126 20 86 46 113 158 62 256 -36 70 -165 109
-261 78z m153 -100 c41 -17 48 -28 48 -72 0 -23 -8 -44 -23 -60 -20 -21 -31
-24 -94 -24 -63 0 -74 3 -94 24 -41 44 -29 115 24 136 32 14 103 11 139 -4z"/>
<path d="M3780 3070 c-11 -11 -20 -28 -21 -37 0 -10 -1 -74 -1 -142 -1 -123
-1 -124 26 -148 25 -21 36 -23 145 -23 195 0 209 12 209 185 0 176 -9 184
-200 185 -125 0 -140 -2 -158 -20z m274 -76 c22 -8 20 -31 -3 -44 -22 -11
-181 -14 -196 -2 -9 6 -10 17 -6 45 1 8 182 10 205 1z m-1 -140 c21 -8 22 -40
1 -48 -9 -3 -57 -6 -109 -6 -91 0 -93 1 -97 25 -3 13 -3 26 -1 29 7 6 188 7
206 0z"/>
<path d="M4286 3074 c-46 -16 -73 -39 -91 -74 -33 -67 -23 -176 22 -224 43
-46 81 -57 200 -54 l106 2 18 31 c13 21 19 49 19 93 0 136 -43 212 -131 231
-64 14 -95 13 -143 -5z m153 -99 c47 -24 56 -83 20 -129 -18 -23 -27 -26 -85
-26 -79 0 -99 10 -113 53 -14 41 -2 78 31 101 28 20 109 21 147 1z"/>
<path d="M4645 3080 c-11 -4 -29 -18 -40 -30 -17 -19 -20 -39 -23 -144 -3
-120 -3 -123 23 -154 l27 -32 144 0 c79 0 144 4 144 9 0 4 -5 22 -11 39 -15
43 -61 62 -153 62 l-76 0 0 124 c0 69 -3 127 -7 129 -5 3 -17 1 -28 -3z"/>
<path d="M5330 3079 c-39 -16 -59 -39 -66 -75 -9 -46 3 -88 33 -118 24 -25 32
-26 127 -26 109 0 145 -12 133 -44 -6 -14 -26 -16 -147 -16 -120 0 -140 -2
-140 -15 0 -26 45 -63 85 -71 58 -10 207 1 241 19 38 20 58 65 50 111 -15 78
-71 106 -210 106 -76 0 -91 6 -80 34 5 14 27 16 140 16 125 0 134 1 134 19 0
10 -9 29 -19 42 -18 23 -27 24 -138 26 -65 2 -129 -2 -143 -8z"/>
<path d="M5800 3081 c-86 -12 -130 -71 -130 -175 0 -81 10 -114 45 -152 l32
-34 120 0 c107 0 123 2 147 21 14 11 26 29 26 40 0 18 -8 19 -129 19 -123 0
-131 1 -145 22 -9 12 -16 26 -16 30 0 5 50 8 111 8 64 0 119 5 132 11 39 22
56 52 57 102 0 96 -83 132 -250 108z m142 -81 c44 -13 54 -25 38 -45 -17 -21
-230 -22 -230 -1 0 42 110 68 192 46z"/>
<path d="M6135 3076 c-57 -25 -60 -35 -57 -200 2 -83 5 -152 7 -154 9 -9 56
10 75 30 17 18 20 35 20 102 0 45 4 86 8 92 11 16 63 33 111 36 39 2 45 6 57
35 25 58 21 63 -45 63 -33 0 -72 -5 -87 -10 -20 -8 -31 -7 -43 4 -13 12 -21
12 -46 2z"/>
<path d="M6423 3083 c-7 -2 -13 -10 -13 -17 0 -7 33 -87 73 -177 l73 -164 40
-3 39 -2 44 97 c24 54 59 133 77 176 l34 78 -24 10 c-38 18 -65 -5 -101 -84
-18 -40 -39 -89 -46 -109 -7 -21 -17 -38 -22 -38 -4 0 -15 17 -23 38 -72 177
-102 215 -151 195z"/>
<path d="M6840 3061 l-30 -29 0 -156 c0 -163 3 -172 42 -142 10 7 22 25 28 40
13 36 13 303 0 311 -6 4 -24 -7 -40 -24z"/>
<path d="M7013 3075 c-80 -34 -119 -176 -73 -267 12 -24 36 -54 54 -66 29 -20
45 -22 148 -22 114 0 117 1 137 26 12 15 21 37 21 50 0 24 -1 24 -112 24 -124
0 -155 10 -176 53 -15 31 -10 55 17 86 16 18 29 21 109 21 50 0 102 5 115 11
26 12 47 47 47 79 0 19 -8 20 -127 20 -86 -1 -138 -5 -160 -15z"/>
<path d="M7464 3081 c-79 -13 -115 -54 -128 -144 -11 -70 9 -148 46 -186 l30
-31 122 0 c66 0 126 4 132 8 13 9 44 54 44 64 0 5 -58 8 -128 8 -112 0 -131 2
-145 18 -33 37 -22 42 91 42 115 0 157 14 182 61 17 31 7 102 -18 127 -38 38
-123 50 -228 33z m169 -88 c21 -6 22 -39 1 -47 -9 -3 -60 -6 -115 -6 -89 0
-99 2 -99 18 0 42 118 61 213 35z"/>
<path d="M7812 3068 c-53 -33 -66 -91 -35 -151 26 -51 39 -56 149 -57 106 0
143 -12 131 -44 -5 -14 -25 -16 -140 -16 -74 0 -136 -3 -140 -6 -3 -4 0 -19 8
-34 22 -43 61 -53 178 -48 142 6 187 32 187 108 0 17 -7 44 -14 59 -26 49 -60
63 -170 70 -92 5 -101 8 -101 26 0 19 8 20 138 23 153 3 162 8 108 63 -29 29
-30 29 -148 29 -103 0 -122 -3 -151 -22z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
